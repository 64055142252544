import {ToastMessageOptions} from "primevue/toast";

const checkErrorCode = (code: number): ToastMessageOptions => {
  switch (code) {
    case 500:
      return {severity: 'error', summary: 'Ошибка: 500', detail: 'внутренняя ошибка сервера', life: 3000};
    case 401:
      return {severity: 'error', summary: 'Ошибка: 401', detail: 'не авторизован', life: 3000};
    case 400:
      return {severity: 'error', summary: 'Ошибка: 400', detail: 'неверный пароль', life: 3000};
    case 404:
      return {severity: 'error', summary: 'Ошибка: 404', detail: 'пользователь не найден', life: 3000};
    case 423:
      return {severity: 'error', summary: 'Ошибка: 423', detail: 'пользователь заблокирован', life: 3000};
    default:
      return {severity: 'error', summary: `Ошибка: ${code}`, detail: 'неизвестная ошибка :(', life: 3000};
  }
};

export default checkErrorCode