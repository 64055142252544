
import {defineComponent, reactive, ref, computed, inject, watch, onMounted, Ref} from 'vue';
import {required} from "@vuelidate/validators";
import {useVuelidate} from "@vuelidate/core";
import {TApi} from "@/api/types";
import {useToast} from "primevue/usetoast";
import {AxiosResponse} from "axios";
import checkErrorCode from "@/source/functions/responseHandler";
import {TObject, TObjectAddUser, TObjNameCode} from "@/source/type/types";
import {TUserCreatePayload, TUserUpdatePayload} from '@/api/modules/users/types';
import {useConfirm} from "primevue/useconfirm";
import {useRouter} from "vue-router";
import store from "@/store";
import {formatDate} from "@/source/functions/formatDate";
import {generatePassword} from "@/source/functions/generatePassword";

export default defineComponent({
    name: 'AddUser',
	methods: {generatePassword},
    props: {
        isEdit: {
            type: Boolean,
            default: false
        },
        userId: {
            type: String,
            default: ''
        },
        noAdmin: {
            type: Boolean,
            default: false
        }
    },
	emits: ['updateData'],
    setup: function (props, { emit }) {
        onMounted(() => {

            if (isEdit.value) {
                blocked.value = true;
                checkToken().then(() => {
                    api.apiUsers.getUser(props.userId).then((res: AxiosResponse) => {
                        addUserObject.lastname = res.data.lastname;
                        addUserObject.firstname = res.data.firstname;
                        addUserObject.middlename = res.data.middlename;
                        addUserObject.email = res.data.email;
                        addUserObject.phone = res.data.phone;
                        addUserObject.gender = res.data.gender === 'male' ? genderOptions[0] : genderOptions[1];
                        addUserObject.birthday = new Date(res.data.birthday);
                        addUserObject.login = res.data.login;
                        addUserObject.password = res.data.password;
                        addUserObject.is_admin = res.data.is_admin;
                        addUserObject.is_superadmin = res.data.is_superadmin;
                        addUserObject.is_locked = res.data.is_locked;
                        compareUserObject.value = {...addUserObject};
                        blocked.value = false;
                    });
                });
            }
        });

        const isEdit = computed(() => props.isEdit);
        const genderOptions: TObjNameCode[] = [
            {name: 'Мужской', code: 'male'},
            {name: 'Женский', code: 'female'},
        ];

        const api: TApi = inject('api') as TApi;
        const toast = useToast();
        const loadItems: any = inject('loadItems');
        const showAddUser: any = inject('showAddUser');
        const router = useRouter();
        const isAdmin = computed(() => store.getters['users/getCurrentUser']?.is_admin ?? false);
        const isSuperadmin = computed(() => store.getters['users/getCurrentUser']?.is_superadmin ?? false);

        const blocked = inject('blocked', ref(false));

        const addUserObject: TObjectAddUser = reactive({
            lastname: null,
            firstname: null,
            middlename: null,
            email: null,
            phone: null,
            gender: null,
            birthday: null,
            login: null,
            password: null,
            is_admin: false,
            is_superadmin: false,
            is_locked: false,
        });

        const compareUserObject: Ref<TObjectAddUser> = ref({
            lastname: null,
            firstname: null,
            middlename: null,
            email: null,
            phone: null,
            gender: null,
            birthday: null,
            login: null,
            password: null,
            is_admin: false,
            is_superadmin: false,
            is_locked: false,
        });

        const checkLogin: any = ref({
            count: 0,
            isLoading: false,
            isExists: false,
            inputTimeout: undefined,
        });

        watch(() => addUserObject.login, () => {
            if (checkLogin.value.inputTimeout) {
                clearTimeout(checkLogin.value.inputTimeout);
            }
            if (!isEdit.value) {
                if (addUserObject.login !== null) {
                    if (addUserObject.login.length === 0) {
                        checkLogin.value.isLoading = false;
                        checkLogin.value.count = 0;
                    }
                    if (addUserObject.login.length > 0) {
                        checkLogin.value.isLoading = true;
                        checkLogin.value.count += 1;
                        checkLogin.value.inputTimeout = setTimeout(() => {
                            api.apiUsers.checkLogin(addUserObject.login ?? '').then((res: AxiosResponse) => {
                                checkLogin.value.isExists = res.data.check;
                            }).finally(() => {
                                checkLogin.value.isLoading = false;
                            });
                        }, 500);
                    }
                }
            } else {
                if (addUserObject.login === compareUserObject.value.login) {
                    checkLogin.value.isExists = false;
                    checkLogin.value.isLoading = false;
                    checkLogin.value.count = 0;
                } else {
                    if (addUserObject.login !== null) {
                        if (addUserObject.login.length === 0) {
                            checkLogin.value.isLoading = false;
                            checkLogin.value.count = 0;
                        }
                        if (addUserObject.login.length > 0) {
                            checkLogin.value.isLoading = true;
                            checkLogin.value.count += 1;
                            checkLogin.value.inputTimeout = setTimeout(() => {
                                api.apiUsers.checkLogin(addUserObject.login ?? '').then((res: AxiosResponse) => {
                                    checkLogin.value.isExists = res.data.check;
                                }).finally(() => {
                                    checkLogin.value.isLoading = false;
                                });
                            }, 500);
                        }
                    }
                }
            }
        });

        const checkEmail: any = ref({
            count: 0,
            isLoading: false,
            isExists: false,
            inputTimeout: undefined,
        });
        watch(() => addUserObject.email, () => {
            if (checkEmail.value.inputTimeout) {
                clearTimeout(checkEmail.value.inputTimeout);
            }
            if (!isEdit.value) {
                if (addUserObject.email !== null) {
                    if (addUserObject.email.length === 0) {
                        checkEmail.value.isLoading = false;
                        checkEmail.value.count = 0;
                    }
                    if (addUserObject.email.length > 0) {
                        checkEmail.value.isLoading = true;
                        checkEmail.value.count += 1;
                        checkEmail.value.inputTimeout = setTimeout(() => {
                            api.apiUsers.checkEmail(addUserObject.email ?? '').then((res: AxiosResponse) => {
                                checkEmail.value.isExists = res.data.check;
                            }).finally(() => {
                                checkEmail.value.isLoading = false;
                            });
                        }, 500);
                    }
                }
            } else {
                if (addUserObject.email === compareUserObject.value.email) {
                    checkEmail.value.isExists = false;
                    checkEmail.value.isLoading = false;
                    checkEmail.value.count = 0;
                } else {
                    if (addUserObject.email !== null) {
                        if (addUserObject.email.length === 0) {
                            checkEmail.value.isLoading = false;
                            checkEmail.value.count = 0;
                        }
                        if (addUserObject.email.length > 0) {
                            checkEmail.value.isLoading = true;
                            checkEmail.value.count += 1;
                            checkEmail.value.inputTimeout = setTimeout(() => {
                                api.apiUsers.checkEmail(addUserObject.email ?? '').then((res: AxiosResponse) => {
                                    checkEmail.value.isExists = res.data.check;
                                }).finally(() => {
                                    checkEmail.value.isLoading = false;
                                });
                            }, 500);
                        }
                    }
                }
            }
        });

        /* Валидация */
        const submitted = ref(false);
        const validateEmail = (email: string) => {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        };
        const source = computed(() => {
            return {
                lastname: addUserObject.lastname,
                firstname: addUserObject.firstname,
                middlename: addUserObject.middlename,
                email: addUserObject.email,
                phone: addUserObject.phone,
                gender: addUserObject.gender,
                birthday: addUserObject.birthday,
                login: addUserObject.login,
                password: addUserObject.password,
            }
        });
        const rules = computed(() => {
            if (!isEdit.value) {
                if (addUserObject.is_superadmin || addUserObject.is_admin) {
                    return {
                        lastname: {required},
                        firstname: {required},
                        middlename: {required},
                        email: {required, validateEmail},
                        gender: {required},
                        login: {required},
                        password: {required},
                    }
                } else {
                    return {
                        lastname: {required},
                        firstname: {required},
                        middlename: {required},
                        email: {required, validateEmail},
                        phone: {required},
                        gender: {required},
                        birthday: {required},
                        login: {required},
                        password: {required},
                    }
                }
            } else {
                let obj: TObject = {}
                if (addUserObject.lastname !== compareUserObject.value.lastname) {
                    obj.lastname = {required}
                }
                if (addUserObject.firstname !== compareUserObject.value.firstname) {
                    obj.firstname = {required}
                }
                if (addUserObject.middlename !== compareUserObject.value.middlename) {
                    obj.middlename = {required}
                }
                if (addUserObject.email !== compareUserObject.value.email) {
                    obj.email = {required, validateEmail}
                }
                if (addUserObject.phone !== compareUserObject.value.phone) {
                    obj.phone = {required}
                }
                if (addUserObject.gender !== compareUserObject.value.gender) {
                    obj.gender = {required}
                }
                if (addUserObject.birthday !== compareUserObject.value.birthday) {
                    obj.birthday = {required}
                }
                if (addUserObject.login !== compareUserObject.value.login) {
                    obj.login = {required}
                }
                if (addUserObject.password !== compareUserObject.value.password) {
                    obj.password = {required}
                }
                return obj
            }
        });
        const v$ = useVuelidate(rules, source);
        const isFormValid = computed(() => !v$.value.$invalid);

        const checkToken: any = inject('checkToken');

        const saveUser = () => {
            submitted.value = true;
            if (!isFormValid.value) {
                return
            }
            const payload = {...addUserObject}
            if (payload.birthday !== null) {
                let birthday = new Date(payload.birthday)
                let day = `${birthday.getDate()}`
                let month = `${String(birthday.getMonth() + 1).length > 1 ? birthday.getMonth() + 1 : '0' + String(birthday.getMonth() + 1)}`
                let year = `${birthday.getFullYear()}`
                payload.birthday = `${year}-${month}-${day}`;
            }
            if (typeof payload.gender === "object" && payload.gender !== null) {
                payload.gender = payload.gender.code;
            }
	        emit('updateData');
            checkToken().then(() => {
                api.apiUsers.createUser(payload as TUserCreatePayload).then((res) => {
	                const url = window.URL.createObjectURL(new Blob([res.data]));
	                const link = document.createElement('a');
	                link.href = url;
	                const date = formatDate(new Date())
					const str = `Пользователь ${payload.lastname} ${payload?.firstname?.toUpperCase()[0]} ${payload?.middlename?.toUpperCase()[0]} - ${date}.pdf`;
	                link.setAttribute('download', str); //or any other extension
	                document.body.appendChild(link);
	                link.click();
                    toast.add({
                        severity: 'success',
                        summary: 'Пользователь создан!',
                        life: 3000
                    });
                }).catch((error: any) => {
                    console.log(error)
                    toast.add({severity: 'error', summary: 'Ошибка!', detail: `Не удалось создать пользователя`, life: 3000});
                    toast.add(checkErrorCode(error.response.status));
                }).finally(() => {
                    if (!props.noAdmin) {
                        loadItems();
                    }
                    showAddUser.value = false;
                });
            })
            submitted.value = false;
        };


        const updateUser = () => {
            submitted.value = true;
            if (!isFormValid.value) {
                return
            }
            let obj: TObject = {}
            if (addUserObject.lastname !== compareUserObject.value.lastname) {
                obj.lastname = addUserObject.lastname;
            }
            if (addUserObject.firstname !== compareUserObject.value.firstname) {
                obj.firstname = addUserObject.firstname;
            }
            if (addUserObject.middlename !== compareUserObject.value.middlename) {
                obj.middlename = addUserObject.middlename;
            }
            if (addUserObject.email !== compareUserObject.value.email) {
                obj.email = addUserObject.email;
            }
            if (addUserObject.phone !== compareUserObject.value.phone) {
                obj.phone = addUserObject.phone;
            }
            if (addUserObject.gender !== compareUserObject.value.gender) {
                if (typeof addUserObject.gender === "object" && addUserObject.gender !== null) {
                    obj.gender = addUserObject.gender.code;
                }
            }
            if (addUserObject.birthday !== compareUserObject.value.birthday) {
                if (addUserObject.birthday !== null) {
                    let birthday = new Date(addUserObject.birthday);
                    let day = `${birthday.getDate()}`;
                    let month = `${String(birthday.getMonth() + 1).length > 1 ? birthday.getMonth() + 1 : '0' + String(birthday.getMonth() + 1)}`;
                    let year = `${birthday.getFullYear()}`;
                    obj.birthday = `${year}-${month}-${day}`;
                }
            }
            if (addUserObject.login !== compareUserObject.value.login) {
                obj.login = addUserObject.login;
            }
            if (addUserObject.password !== compareUserObject.value.password) {
                obj.password = addUserObject.password;
            }
            if (addUserObject.is_admin !== compareUserObject.value.is_admin) {
                obj.is_admin = addUserObject.is_admin;
            }
            if (addUserObject.is_superadmin !== compareUserObject.value.is_superadmin) {
                obj.is_superadmin = addUserObject.is_superadmin;
            }
            if (addUserObject.is_locked !== compareUserObject.value.is_locked) {
                obj.is_locked = addUserObject.is_locked;
            }
	        emit('updateData');
            checkToken().then(() => {
                api.apiUsers.updateUser(props.userId, obj as TUserUpdatePayload).then((res) => {
					if (addUserObject.password !== compareUserObject.value.password) {
						const url = window.URL.createObjectURL(new Blob([res.data]));
						const link = document.createElement('a');
						link.href = url;
						const user = compareUserObject.value;
						const date = formatDate(new Date())
						const str = `Пользователь ${user?.lastname} ${user?.firstname?.toUpperCase()[0]} ${user?.middlename?.toUpperCase()[0]} - ${date}.pdf`;
						link.setAttribute('download', str); //or any other extension
						document.body.appendChild(link);
						link.click();
					}
                    toast.add({
                        severity: 'success',
                        summary: 'Данные пользователя обновлены!',
                        life: 3000
                    });
                }).catch((error: any) => {
                    console.log(error);
                    toast.add({
                        severity: 'error',
                        summary: 'Ошибка!',
                        detail: `Не удалось обновить данные пользователя`,
                        life: 3000
                    });
                    toast.add(checkErrorCode(error.response.status));
                }).finally(() => {
                    if (!props.noAdmin) {
                        loadItems();
                    }
                    showAddUser.value = false;
                });
            });
            submitted.value = false;
        };


        const cancelAddUser = () => {
            showAddUser.value = false;
        };

        type TKey = keyof typeof addUserObject;

        const saveButtonDisable = computed(() => {
            let check = false;
            if (isEdit.value) {
                check = true;
                for (let key in addUserObject) {
                    if (addUserObject[key as TKey] !== compareUserObject.value[key as TKey]) {
                        check = false;
                    }
                }
            }
            return check
        });

        const confirm = useConfirm();

        const deleteUser = (event: any) => {
            confirm.require({
                target: event.currentTarget,
                message: 'Вы действительно хотите удалить пользователя?',
                icon: 'pi pi-exclamation-triangle',
                accept: () => {
                    checkToken().then(() => {
                        api.apiUsers.deleteUser(props.userId).then(() => {
                            toast.add({
                                severity: 'success',
                                summary: 'Пользователь удален',
                                life: 3000
                            });
                        }).catch((error: any) => {
                            console.log(error)
                            toast.add({severity: 'error', summary: 'Ошибка!', detail: `Не удалось удалить пользователя`, life: 3000});
                            toast.add(checkErrorCode(error.response.status));
                        }).finally(() => {
                            if (!props.noAdmin) {
                                loadItems();
                            }
                            showAddUser.value = false;
                        });
                    });
                },
                reject: () => {
                    //callback to execute when user rejects the action
                }
            });
        };

		const showText = ref(false);


        return {
            genderOptions,
            addUserObject,
            v$,
            submitted,
            checkLogin,
            checkEmail,
            saveButtonDisable,
            isAdmin,
            isSuperadmin,
            blocked,
	        showText,
            saveUser,
            updateUser,
            cancelAddUser,
            deleteUser,
        }
    }
});

